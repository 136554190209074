import axios, { AxiosInstance } from "axios";

export interface AppointmentReportDiagnoses {
  cie: string;
  name: string;
}

export interface DifferentialDiagnosisResponse {
  cie: string;
  name: string;
  inference: string;
}

export interface DiagnosisResponse {
  cie: string;
  name: string;
  study_plan: string;
  treatment_plan: string;
  monitoring_plan: string;
  disability_days: string;
}

export interface AppointmentReportResponse {
  id: string;
  risk_factor: string;
  medical_record_summary: string;
  definitive_diagnosis: DiagnosisResponse | null;
  differential_diagnosis: DifferentialDiagnosisResponse[];
}

export interface Case {
  index: number;
  cie: string;
  medical_record: string;
  triage_resume: string;
  doctor_observation: string;
  patient_name: string;
}

class ServerManager {
  private serverInstance!: AxiosInstance;

  constructor() {
    this.checkEnvironment();
    this.buildServerInstances();
    this.createTimerInterceptor();
    this.createAxiosResponseInterceptor();
  }

  private checkEnvironment() {
    if (!process.env.REACT_APP_API_BASE_URL) {
      throw new Error("REACT_APP_API_BASE_URL is not defined");
    }
    if (!process.env.REACT_APP_API_KEY) {
      throw new Error("REACT_APP_API_KEY is not defined");
    }
  }

  buildServerInstances() {
    this.serverInstance = axios.create({
      baseURL: process.env.REACT_APP_API_BASE_URL,
      headers: {
        "X-API-KEY": process.env.REACT_APP_API_KEY,
      },
    });
  }

  rebuild() {
    this.buildServerInstances();
    this.createTimerInterceptor();
    this.createAxiosResponseInterceptor();
  }

  private createAxiosResponseInterceptor() {
    // const interceptor = this.serverInstance.interceptors.response.use(
    //   (response) => response,
    //   (error) => {
    //     if (error.response.status !== 401) {
    //       return Promise.reject(error);
    //     }
    //     /*
    //      * When response code is 401, try to refresh the token.
    //      * Eject the interceptor so it doesn't loop in case
    //      * token refresh causes the 401 response.
    //      *
    //      * Must be re-attached later on or the token refresh will only happen once
    //      */
    //     this.serverInstance.interceptors.response.eject(interceptor);
    //   }
    // );
  }

  private createTimerInterceptor() {
    if (!process.env.REACT_APP_ENABLE_LOGS) return;
    // Add a request interceptor
    this.serverInstance.interceptors.request.use(
      (config) => {
        (config as any).metadata = {
          startTime: new Date().valueOf(),
        };
        return config;
      },
      (error) => {
        return Promise.reject(error);
      },
    );

    // Add a response interceptor
    this.serverInstance.interceptors.response.use(
      (response) => {
        const requestTime =
          (new Date().valueOf() - (response.config as any).metadata.startTime) /
          1000; // Calculate request time
        console.log(
          `Request took ${requestTime} s. (${response.config.method?.toUpperCase()} ${
            response.config.url
          })`,
        );

        return response;
      },
      (error) => {
        return Promise.reject(error);
      },
    );
  }

  createReportForMedicalAppoinment(
    appointmentId: string,
    doctorObservation: string,
    triajeInformation: string,
  ) {
    return this.serverInstance.post<AppointmentReportResponse>(
      "/v1/appointment-report",
      {
        appointment_id: appointmentId,
        doctor_observation: doctorObservation,
        triage_information: triajeInformation,
      },
    );
  }
  getDiagnosesList() {
    return this.serverInstance.get<AppointmentReportDiagnoses[]>(
      "/v1/appointment-report/diagnoses",
    );
  }
  getReportForMedicalAppoinment(id: string) {
    return this.serverInstance.get<AppointmentReportResponse>(
      `/v1/appointment-report/${id}/`,
    );
  }
  updateReportForMedicalAppoinment(
    id: string,
    appointmentId: string,
    doctorObservation: string,
    triajeInformation: string,
  ) {
    return this.serverInstance.put<AppointmentReportResponse>(
      `/v1/appointment-report/${id}/`,
      {
        appointment_id: appointmentId,
        doctor_observation: doctorObservation,
        triage_information: triajeInformation,
      },
    );
  }
  addDefinitiveDiagnose(id: string, cie: string) {
    return this.serverInstance.patch<DiagnosisResponse>(
      `v1/appointment-report/${id}/definitive-diagnose/?cie=${cie}`,
    );
  }
  removeDefinitiveDiagnose(id: string) {
    return this.serverInstance.delete(
      `v1/appointment-report/${id}/definitive-diagnose/`,
    );
  }
  addDifferentialDiagnose(id: string, cie: string) {
    return this.serverInstance.patch(
      `v1/appointment-report/${id}/differential-diagnose/?cie=${cie}`,
    );
  }
  removeDifferentialDiagnose(id: string, cie: string) {
    return this.serverInstance.delete(
      `v1/appointment-report/${id}/differential-diagnose/?cie=${cie}`,
    );
  }
  getCasesList() {
    return this.serverInstance.get<Case[]>("v1/appointment-report/cases");
  }
}

const serverManager = new ServerManager();

export default serverManager;
