import { useState, useEffect } from "react";
import { useTheme } from "@mui/material/styles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  AccordionSummary,
  Accordion,
  AccordionDetails,
} from "./DiagnosticAccordionCustoms";
import {
  Autocomplete,
  Button,
  Container,
  IconButton,
  ListItemText,
  TextField,
} from "@mui/material";

import { Accordion as AccordionUi } from "@mui/material";
import { AccordionSummary as AccordionSummaryUI } from "@mui/material";
import { AccordionDetails as AccordionDetailsUI } from "@mui/material";

import ReactMarkdown from "react-markdown";
import { Check } from "@mui/icons-material";
import { serverManager } from "../../apis";
import {
  AppointmentReportDiagnoses,
  DifferentialDiagnosisResponse,
} from "../../apis/server/server";

const styles = {
  contentFactor: {
    padding: "16px",
    paddingX: "14px",
    borderRadius: "8px",
    gap: "18px",
    display: "grid",
    width: "100%",
  },
  contentDiagnostic: {
    padding: "16px",
    borderRadius: "8px",
    gap: "18px",
    display: "grid",
    paddingX: "24px",
    width: "100%",
  },
  contentForm: {
    display: "flex",
    justifyContent: "center",
    width: "100%",
  },
  bodyForm: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    width: "75%",
    minWidth: "280px",
  },
  contentTabs: {
    flexGrow: 1,
    bgcolor: "background.paper",
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  titleDiagnose: {
    display: "block",
    fontSize: "2em",
    fontWeight: "bold",
    marginBlockStart: "0.67em",
    marginBlockEnd: "0.67em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
  },
  titleTabs: {
    display: "flex",
    flexDirection: "row-reverse",
    paddingX: "4px",
  },
  indicator: {
    borderRight: 1,
    borderColor: "divider",
    "& button": {
      padding: "0.3em",
      marginLeft: "0.3em",
    },
    "& button[aria-selected='true']": {
      position: "relative",

      "&:before": {
        content: '""',
        position: "absolute",
        left: 0,
        top: 0,
        right: 0,
        bottom: 0,
        background: "linear-gradient( #6B7FFE, #53C0FF )",
        zIndex: 0,
        borderRadius: "8px",
      },

      "& > *": { zIndex: 0 },
      "& > .MuiTab-wrapper": {
        background: "#fff",
        trnsition: "",
      },
    },
  },
  accordionSumary: {
    display: "flex",
    flexDirection: "row-reverse",
  },
  containerTab: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "35px",
    paddingX: "2px !important",
  },
};

export interface DiagnosticTabsProps {
  differentialDiagnosisData: DifferentialDiagnosisResponse[];
  hanledAddNewDiagnose: (cie: string) => void;
  handleRemoveDiagnose: (cie: string) => void;
  handleDiagnoseSelected: (selected: string) => void;
  definitiveDiagnoseIsSelected: boolean;
  expanded: boolean;
  handleChangeExpanded: (
    event: React.SyntheticEvent,
    newExpanded: boolean,
  ) => void;
}

export default function DiagnosticTabs({
  differentialDiagnosisData,
  handleDiagnoseSelected,
  hanledAddNewDiagnose,
  handleRemoveDiagnose,
  definitiveDiagnoseIsSelected,
  expanded,
  handleChangeExpanded,
}: DiagnosticTabsProps) {
  const theme = useTheme();
  const [diagnosesList, setDiagnosesTitle] = useState<
    AppointmentReportDiagnoses[]
  >([]);
  const [newDiagnose, setNewDiagnose] = useState<string>("");
  const [expandedDiagnose, setExpandedDiagnose] = useState<string | false>(
    false,
  );

  useEffect(() => {
    serverManager.getDiagnosesList().then(({ data }) => {
      setDiagnosesTitle(data);
    });
  }, []);

  const diagnosesListFiltered = diagnosesList.filter(
    (c) => !differentialDiagnosisData.some((d) => c.cie === d.cie),
  );

  const handleChangeAccordionDiagnose =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpandedDiagnose(newExpanded ? panel : false);
    };
  return (
    <Box sx={styles.contentTabs}>
      <Container sx={{ display: "flex", paddingX: "0px !important" }}>
        <Box
          sx={{
            ...styles.contentFactor,
            background: theme.palette.background.paper,
          }}
        >
          <Accordion expanded={expanded} onChange={handleChangeExpanded}>
            <AccordionSummary
              aria-controls="panel2d-content"
              id="panel2d-header"
              sx={styles.accordionSumary}
            >
              <Typography variant="h2" sx={styles.titleDiagnose}>
                Diagnósticos
              </Typography>
            </AccordionSummary>
            {differentialDiagnosisData.map(
              (data: DifferentialDiagnosisResponse, index) => (
                <AccordionDetails key={data.cie}>
                  <AccordionUi
                    expanded={expandedDiagnose === data.cie}
                    onChange={handleChangeAccordionDiagnose(data.cie)}
                  >
                    <AccordionSummaryUI expandIcon={<ExpandMoreIcon />}>
                      <Typography variant="h6">{data.name}</Typography>
                    </AccordionSummaryUI>
                    <AccordionDetailsUI>
                      <ReactMarkdown>
                        {differentialDiagnosisData[index].inference}
                      </ReactMarkdown>
                    </AccordionDetailsUI>
                    {!definitiveDiagnoseIsSelected && (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          mb: "16px",
                          gap: "16px",
                        }}
                      >
                        <Button
                          variant="contained"
                          onClick={() => handleDiagnoseSelected(data.cie)}
                        >
                          Elegir Diagnóstico
                        </Button>
                        <Button
                          variant="contained"
                          color="error"
                          aria-label="close"
                          onClick={() => handleRemoveDiagnose(data.cie)}
                        >
                          Eliminar Diagnóstico
                        </Button>
                      </Box>
                    )}
                  </AccordionUi>
                </AccordionDetails>
              ),
            )}
            {diagnosesList.length > 0 && (
              <>
                <Autocomplete
                  fullWidth
                  sx={{ padding: "16px" }}
                  options={diagnosesListFiltered}
                  getOptionLabel={(diagnose) => diagnose.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Añadir diagnóstico"
                      id="diagnosesNew"
                      name="diagnosesew"
                    />
                  )}
                  onChange={(e, diagnose) => {
                    if (diagnose) {
                      setNewDiagnose(diagnose.cie);
                    } else {
                      setNewDiagnose("");
                    }
                  }}
                  renderOption={(props, diagnose) => (
                    <li {...props} key={diagnose.cie}>
                      <ListItemText>{diagnose.name}</ListItemText>
                    </li>
                  )}
                />
                <Container sx={{ display: "flex", width: "10%" }}>
                  <IconButton onClick={() => hanledAddNewDiagnose(newDiagnose)}>
                    <Check />
                  </IconButton>
                </Container>
              </>
            )}
          </Accordion>
        </Box>
      </Container>
    </Box>
  );
}
