import {
  Backdrop,
  Box,
  CircularProgress,
  Container,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useMediaQuery,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { serverManager } from "../apis";
import DiagnosticsPanelAI from "../components/DiagnosticsPanel/DiagnosticsPanelAI";
import { Case } from "../apis/server/server";

const styles = {
  headForm: {
    alignItems: "center",
    display: "flex",
    justifyContent: "end",
    flexDirection: "row",
    pl: 0,
    pr: 0,
    width: "100%",
  },
  bodyForm: {
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    width: "60%",
  },
  bodeyFormResponsive: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    flexDirection: "column",
    mb: 2,
    ml: 0,
    mr: 0,
    mt: 2,
    pl: 0,
    pr: 0,
  },
  form: {
    display: "flex",
    flexDirection: "column",
    gap: "24px",
    minWidth: "280px",
  },
  contentButton: {
    alignItems: "center",
    alignSelf: "center",
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
  },
};

export default function Diagnostics() {
  const [doctorObservations, setDoctorObservations] = useState<string>("");
  const [triajeInformation, setTriajeInformation] = useState<string>("");
  const [cases, setCases] = useState<Case>();
  const [isLoading, setLoading] = useState<boolean>(false);
  const [hasActiveReport, setHasActiveReport] = useState<boolean>(false);
  const [reportId, setReportId] = useState<string>("");
  const [appointmentId, setAppointmentId] = useState<string>("");
  const [availableCases, setAvailableCases] = useState<Case[]>([]);
  const [healthCareData, setHealthCareData] = useState<string>("");
  const [caseIndex, setCaseIndex] = useState<number>(1);

  const matches = useMediaQuery("(max-width:712px)");

  const sendDiagnostic = () => {
    setLoading(true);
    setHasActiveReport(true);
    setAppointmentId("");
    setReportId("");
    const appointmentIdSelected = `${cases?.patient_name}-${cases?.index}-${Date.now()}`;
    serverManager
      .createReportForMedicalAppoinment(
        appointmentIdSelected,
        doctorObservations,
        triajeInformation,
      )
      .then(({ data }) => {
        setAppointmentId(appointmentIdSelected);
        setReportId(data.id);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleUpdateReport = () => {
    setLoading(true);
    setReportId("");
    serverManager
      .updateReportForMedicalAppoinment(
        reportId,
        appointmentId,
        doctorObservations,
        triajeInformation,
      )
      .then(({ data }) => {
        setReportId(data.id);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    setLoading(true);
    serverManager
      .getCasesList()
      .then(({ data }) => {
        setAvailableCases(data);
        setCaseIndex(data[0].index);
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    if (availableCases.length > 0) {
      setCases(availableCases[0]);
    }
  }, [availableCases]);

  useEffect(() => {
    setHealthCareData(cases?.medical_record.replaceAll("\n", "\n\n") || "");
    setDoctorObservations(cases?.doctor_observation || "");
    setTriajeInformation(cases?.triage_resume || "");
  }, [cases]);

  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        width: `${!matches ? "50%" : "100%"} `,
        margin: "0 0",
      }}
    >
      <Container sx={!matches ? styles.bodyForm : styles.bodeyFormResponsive}>
        <Box sx={styles.form}>
          <FormControl fullWidth sx={{ marginTop: "15px" }}>
            <InputLabel id="cases-label">Casos</InputLabel>
            {availableCases.length > 0 && (
              <Select
                labelId="cases-label"
                id="cases"
                label="Casos"
                name="cases"
                onChange={(e) => {
                  const newCase = availableCases.find(
                    (c) => c.index === e.target.value,
                  );
                  setCases(newCase);
                  setCaseIndex(Number(e.target.value));
                }}
                value={caseIndex}
                disabled={hasActiveReport}
              >
                {availableCases?.map((cc) => (
                  <MenuItem value={cc.index} key={cc.index + 1}>
                    Caso #{cc.index + 1} - {cc.patient_name}
                  </MenuItem>
                ))}
              </Select>
            )}
          </FormControl>
          <TextField
            value={doctorObservations}
            onChange={({ target }) => setDoctorObservations(target.value)}
            multiline
            label={"Observaciones del doctor"}
            rows={12}
            fullWidth
          />
          <TextField
            value={triajeInformation}
            onChange={({ target }) => setTriajeInformation(target.value)}
            multiline
            label={"Resultado del triaje"}
            rows={12}
            fullWidth
          />
        </Box>
        <DiagnosticsPanelAI
          isLoading={isLoading}
          setLoading={setLoading}
          hasActiveReport={hasActiveReport}
          setHasActiveReport={setHasActiveReport}
          reportId={reportId}
          handleUpdateReport={handleUpdateReport}
          sendDiagnostic={sendDiagnostic}
          healthCareData={healthCareData}
        />
      </Container>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Container>
  );
}
