import { Box, Container, Link, Typography } from "@mui/material";

export default function NotFound() {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        alignContent: "center",
        height: "100%",
      }}
    >
      <Box style={{ marginTop: "20%" }}>
        <Typography fontWeight={600} variant="h2">
          404
        </Typography>
        <Typography variant="h4">Pagina no encontrada</Typography>
      </Box>
      <Link sx={{ marginTop: "24px" }} href="/">
        Volver a la pagina de inicio
      </Link>
    </Container>
  );
}
