import React, { useState, useEffect, useCallback } from "react";
import { useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import {
  Button,
  Container,
  Divider,
  Typography,
  useMediaQuery,
} from "@mui/material";
import ReactMarkdown from "react-markdown";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./DiagnosticAccordionCustoms";
import DiagnosticTabs from "./DiagnosticTabs";
import SkeletonDiagnostic from "./SkeletonDiagnostic";
import { serverManager } from "../../apis";
import {
  DiagnosisResponse,
  DifferentialDiagnosisResponse,
} from "../../apis/server/server";

const drawerWidthComplete = "100%";
const drawerWidthMid = "50%";

const styles = {
  contentFactor: {
    padding: "16px",
    paddingX: "14px",
    borderRadius: "8px",
    gap: "18px",
    display: "grid",
    width: "100%",
  },
  titleDiagnose: {
    display: "block",
    fontSize: "2em",
    fontWeight: "bold",
    marginBlockStart: "0.67em",
    marginBlockEnd: "0.67em",
    marginInlineStart: "0px",
    marginInlineEnd: "0px",
    padding: "0 16px",
  },

  accordionSumary: {
    display: "flex",
    flexDirection: "row-reverse",
  },
};
interface PropsSideBar {
  hasActiveReport: boolean;
  setHasActiveReport: (value: React.SetStateAction<boolean>) => void;
  isLoading: boolean;
  setLoading: (value: React.SetStateAction<boolean>) => void;
  reportId: string;
  handleUpdateReport: () => void;
  sendDiagnostic: () => void;
  healthCareData: string;
}

export default function DiagnosticsPanelAI({
  hasActiveReport,
  setHasActiveReport,
  isLoading,
  setLoading,
  reportId,
  handleUpdateReport,
  sendDiagnostic,
  healthCareData,
}: PropsSideBar) {
  const matches = useMediaQuery("(max-width:712px)");
  const theme = useTheme();
  const [riskFactorsData, setRiskFactorsData] = useState<string>("");
  const [medicalRecordData, setMedicalRecordData] = useState<string>("");
  const [differentialDiagnosisData, setDifferentialDiagnosisData] = useState<
    DifferentialDiagnosisResponse[]
  >([]);
  const [definitiveDiagnosisData, setDefinitiveDiagnosisData] =
    useState<DiagnosisResponse | null>(null);
  const [expanded, setExpanded] = useState<string | false>("panel1");
  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
    };

  const handleChooseDefinitiveDiagnosisData = (cie: string) => {
    setLoading(true);
    serverManager
      .addDefinitiveDiagnose(reportId, cie)
      .then(({ data }) => {
        setDefinitiveDiagnosisData(data);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleCancelDefinitiveDiagnosisData = () => {
    setLoading(true);
    serverManager
      .removeDefinitiveDiagnose(reportId)
      .then(() => {
        setDefinitiveDiagnosisData(null);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getReport = useCallback(() => {
    if (reportId !== "") {
      setLoading(true);
      serverManager
        .getReportForMedicalAppoinment(reportId)
        .then(({ data }) => {
          setMedicalRecordData(data.medical_record_summary);
          setRiskFactorsData(data.risk_factor);
          setDifferentialDiagnosisData(data.differential_diagnosis);
          setDefinitiveDiagnosisData(data.definitive_diagnosis);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setMedicalRecordData("");
      setRiskFactorsData("");
      setDifferentialDiagnosisData([]);
      setDefinitiveDiagnosisData(null);
    }
  }, [reportId, setLoading]);
  const hanledAddNewDiagnose = (cie: string) => {
    if (!cie) return;
    setLoading(true);
    serverManager
      .addDifferentialDiagnose(reportId, cie)
      .then(() => {
        getReport();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleRemoveDiagnose = (cie: string) => {
    setLoading(true);
    serverManager
      .removeDifferentialDiagnose(reportId, cie)
      .then(() => {
        getReport();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const cleanCaseData = () => {
    setMedicalRecordData("");
    setRiskFactorsData("");
    setDifferentialDiagnosisData([]);
    setDefinitiveDiagnosisData(null);
  };

  useEffect(() => {
    getReport();
  }, [getReport]);

  return (
    <Box>
      <CssBaseline />
      <Drawer
        sx={{
          width: !matches ? drawerWidthMid : drawerWidthComplete,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: !matches ? drawerWidthMid : drawerWidthComplete,
          },
          p: 0,
        }}
        variant="persistent"
        anchor="right"
        open={true}
      >
        {isLoading ? (
          <SkeletonDiagnostic />
        ) : (
          <Container
            sx={{
              display: "flex",
              flexDirection: "column",
              paddingX: "0px",
              alignItems: "center",
            }}
          >
            {!hasActiveReport && (
              <Button
                disabled={isLoading}
                onClick={sendDiagnostic}
                sx={{ height: "40px", width: "40%", margin: "12px 0" }}
              >
                <img
                  style={{ marginRight: "10px" }}
                  src="/intellisys-hd.png"
                  width="24px"
                  alt="intellisys"
                />
                <span>Consultar a Galenus AI</span>
              </Button>
            )}
            {hasActiveReport && (
              <Button
                variant="outlined"
                disabled={isLoading}
                onClick={handleUpdateReport}
                sx={{ height: "40px", width: "100%", margin: "12px 0" }}
              >
                <img
                  style={{ marginRight: "10px" }}
                  src="/intellisys-hd.png"
                  width="30px"
                  alt="intellisys"
                />
                <span>Reconsultar a Galenus AI</span>
              </Button>
            )}
            {healthCareData !== "" && (
              <Box
                sx={{
                  ...styles.contentFactor,
                  background: theme.palette.background.paper,
                }}
              >
                <Accordion
                  expanded={expanded === "panel9"}
                  onChange={handleChange("panel9")}
                >
                  <AccordionSummary
                    sx={styles.accordionSumary}
                    aria-controls="panel1d-content"
                    id="panel1d-header"
                  >
                    <Typography variant="h2" sx={styles.titleDiagnose}>
                      Datos de Health Care
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ReactMarkdown>{healthCareData}</ReactMarkdown>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            <Divider />
            {medicalRecordData !== "" && (
              <Box
                sx={{
                  ...styles.contentFactor,
                  background: theme.palette.background.paper,
                }}
              >
                <Accordion
                  expanded={expanded === "panel99"}
                  onChange={handleChange("panel99")}
                >
                  <AccordionSummary
                    sx={styles.accordionSumary}
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <Typography variant="h2" sx={styles.titleDiagnose}>
                      Registro médico
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ReactMarkdown>{medicalRecordData}</ReactMarkdown>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            {riskFactorsData !== "" && (
              <Box
                sx={{
                  ...styles.contentFactor,
                  background: theme.palette.background.paper,
                }}
              >
                <Accordion
                  expanded={expanded === "panel2"}
                  onChange={handleChange("panel2")}
                >
                  <AccordionSummary
                    sx={styles.accordionSumary}
                    aria-controls="panel2d-content"
                    id="panel2d-header"
                  >
                    <Typography variant="h2" sx={styles.titleDiagnose}>
                      Factores de riesgo
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ReactMarkdown>{riskFactorsData}</ReactMarkdown>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            {differentialDiagnosisData.length > 0 && (
              <DiagnosticTabs
                differentialDiagnosisData={differentialDiagnosisData}
                hanledAddNewDiagnose={hanledAddNewDiagnose}
                handleRemoveDiagnose={handleRemoveDiagnose}
                handleDiagnoseSelected={handleChooseDefinitiveDiagnosisData}
                definitiveDiagnoseIsSelected={definitiveDiagnosisData !== null}
                expanded={expanded === "panelD9"}
                handleChangeExpanded={handleChange("panelD9")}
              />
            )}
            {hasActiveReport && (
              <Container
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              ></Container>
            )}
            {definitiveDiagnosisData !== null &&
              definitiveDiagnosisData.name && (
                <Box
                  sx={{
                    ...styles.contentFactor,
                    background: theme.palette.background.paper,
                  }}
                >
                  <Typography variant="h2" sx={styles.titleDiagnose}>
                    Diagnóstico definitivo: {definitiveDiagnosisData.name}
                  </Typography>
                </Box>
              )}
            {definitiveDiagnosisData !== null &&
              definitiveDiagnosisData.study_plan && (
                <Box
                  sx={{
                    ...styles.contentFactor,
                    background: theme.palette.background.paper,
                  }}
                >
                  <Accordion
                    expanded={expanded === `panel3`}
                    onChange={handleChange(`panel3`)}
                  >
                    <AccordionSummary
                      sx={styles.accordionSumary}
                      aria-controls="panel2d-content"
                      id="panel2d-header"
                    >
                      <Typography variant="h2" sx={styles.titleDiagnose}>
                        Plan de estudio
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ReactMarkdown>
                        {definitiveDiagnosisData.study_plan}
                      </ReactMarkdown>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
            {definitiveDiagnosisData !== null &&
              definitiveDiagnosisData.treatment_plan && (
                <Box
                  sx={{
                    ...styles.contentFactor,
                    background: theme.palette.background.paper,
                  }}
                >
                  <Accordion
                    expanded={expanded === `panel4`}
                    onChange={handleChange(`panel4`)}
                  >
                    <AccordionSummary
                      sx={styles.accordionSumary}
                      aria-controls="panel2d-content"
                      id="panel2d-header"
                    >
                      <Typography variant="h2" sx={styles.titleDiagnose}>
                        Plan de tratamiento
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <ReactMarkdown>
                        {definitiveDiagnosisData.treatment_plan}
                      </ReactMarkdown>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              )}
            {definitiveDiagnosisData !== null && (
              <Box
                sx={{
                  ...styles.contentFactor,
                  background: theme.palette.background.paper,
                }}
              >
                <Accordion
                  expanded={expanded === `panel5`}
                  onChange={handleChange(`panel5`)}
                >
                  <AccordionSummary
                    sx={styles.accordionSumary}
                    aria-controls="panel5d-content"
                    id="panel5d-header"
                  >
                    <Typography variant="h2" sx={styles.titleDiagnose}>
                      Diagnóstico diferencial
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails>
                    <ul>
                      {differentialDiagnosisData
                        .filter((dd) => dd.cie !== definitiveDiagnosisData.cie)
                        .map((dd, index) => (
                          <li key={index}>{dd.name}</li>
                        ))}
                    </ul>
                  </AccordionDetails>
                </Accordion>
              </Box>
            )}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                mb: "16px",
                gap: "16px",
              }}
            >
              <Button
                disabled={!hasActiveReport || isLoading}
                onClick={() => {
                  setHasActiveReport(false);
                  cleanCaseData();
                }}
                color="primary"
                variant="contained"
              >
                <span>Terminar report</span>
              </Button>
              {definitiveDiagnosisData !== null && (
                <Button
                  variant="contained"
                  color="error"
                  onClick={handleCancelDefinitiveDiagnosisData}
                >
                  Cancelar diagnóstico definitivo
                </Button>
              )}
            </Box>
          </Container>
        )}
      </Drawer>
    </Box>
  );
}
