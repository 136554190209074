import { Box, Divider, Skeleton, useTheme } from "@mui/material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
} from "./DiagnosticAccordionCustoms";

const styles = {
  contentFactor: {
    padding: "16px",
    paddingX: "14px",
    borderRadius: "8px",
    gap: "18px",
    display: "grid",
    width: "100%",
  },
};

export default function SkeletonDiagnostic() {
  const theme = useTheme();
  return (
    <>
      <Box>
        <Skeleton
          variant="rectangular"
          width="100%"
          height={64}
          animation="wave"
        />
        <Divider />
        <Box
          sx={{
            ...styles.contentFactor,
            background: theme.palette.background.paper,
          }}
        >
          <Accordion>
            <AccordionSummary>
              <Skeleton
                variant="text"
                width="30%"
                height={20}
                animation="wave"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Skeleton
                variant="text"
                width="100%"
                height={40}
                animation="wave"
              />
            </AccordionDetails>
          </Accordion>
        </Box>
        <Divider />
        <Box
          sx={{
            ...styles.contentFactor,
            background: theme.palette.background.paper,
          }}
        >
          <Accordion>
            <AccordionSummary>
              <Skeleton
                variant="text"
                width="30%"
                height={20}
                animation="wave"
              />
            </AccordionSummary>
            <AccordionDetails>
              <Skeleton
                variant="text"
                width="100%"
                height={40}
                animation="wave"
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
      <Box>
        {/* Skeleton rectangular */}
        <Skeleton
          variant="rectangular"
          width="100%"
          height={64}
          animation="wave"
        />
        <Divider />

        {/* Primer bloque de contenido con Accordion */}
        <Box
          sx={{
            ...styles.contentFactor,
            background: theme.palette.background.paper,
          }}
        >
          <Accordion>
            <AccordionSummary>
              {/* Etiqueta del Accordion */}
              <Skeleton
                variant="text"
                width="30%"
                height={20}
                animation="wave"
              />
            </AccordionSummary>
            <AccordionDetails>
              {/* Contenido del Accordion */}
              <Skeleton
                variant="text"
                width="100%"
                height={40}
                animation="wave"
              />
            </AccordionDetails>
          </Accordion>
        </Box>

        <Divider />

        {/* Segundo bloque de contenido con Accordion */}
        <Box
          sx={{
            ...styles.contentFactor,
            background: theme.palette.background.paper,
          }}
        >
          <Accordion>
            <AccordionSummary>
              {/* Etiqueta del Accordion */}
              <Skeleton
                variant="text"
                width="30%"
                height={20}
                animation="wave"
              />
            </AccordionSummary>
            <AccordionDetails>
              {/* Contenido del Accordion */}
              <Skeleton
                variant="text"
                width="100%"
                height={40}
                animation="wave"
              />
            </AccordionDetails>
          </Accordion>
        </Box>
      </Box>
    </>
  );
}
